.Summary{
    padding: 40px;
    margin: auto;
    width: 45%;
    text-align: left;
    border-radius: 20px;
    background-color: #FFFFFF;
}

.FullWidth {
    width: 100%;
}

.SummaryTitle{
    font-size: 24px;
    font-weight: bold;
}

.SummaryDetails {
    display: inline-flex;
    justify-content: space-between;
    width: 100%;
}

.SummaryDetails span {
    margin-top: 15px;
}

.SummaryLabels {
    display: grid;
    font-size: 18px;
    color: #777777;
}

.SummaryValues {
    display: grid;
    display: grid;
    font-size: 18px;
    text-align: right;
}

.editButton {
    margin-left: 10px; 
    border-width: 0;
    background-color: transparent;
    cursor: pointer;
}