.body, #root {
   width: 100%;
   height: 100vh;
}

:focus {
    outline: none;
}

/* @media only screen and (min-width: 1500px) { */
    .ui.container {
        width: 98%;
        margin-left: auto !important;
        margin-right: auto !important
    }
/* } */


@media only screen and (min-width: 768px) {
    .form-label {
        text-align: right;
        width: 100%!important;
        margin-right: 15px!important;
    }
}

@media only screen and (max-width: 767px) {
    .form-control {
        margin-left: 15px!important;
    }
}


.ui.progress .bar>.progress {
    right: .1em;
}

.form-control {
    font-weight: 100!important;
}

.rt-th {
    text-align: left !important;
}

.-headerGroups {
    display: none !important;
}

.rt-thead {
    border-bottom-style: double;
    border-color: #0000001a;
}

.rt-tr {
    border-bottom: 1px solid #0000001a;
}

.ReactTable.-highlight .rt-tbody .rt-tr:not(.-padRow):hover {
    background: #def;
}

.ReactTable .rt-thead.-header {
    box-shadow: none!important;
}

.rt-td .ui.button {
    padding-top: 0.55em;
    padding-bottom: 0.45em;
}

.Table__itemCount {
    /* margin-top: 10px; */
    font-size: 14px;
}

.Table__pagination {
    display: flex;
    justify-content: flex-end;
    padding: 20px 10px 10px;
}

.Table__pageButton {
    font-size: 15px;
    outline: none;
    border: none;
    background-color: transparent;
    cursor: pointer;
}

.Table__pageButton:disabled {
    cursor: not-allowed;
    color: gray;
}

.Table__pageButton--active {
    color: #1b1c1d;
    font-weight: bold;
}


.ReactTable .rt-noData {
    top: 55%!important;
    background: #fff0;
}


.rt-tr.-odd.disabled {
    background: #00000008!important;
}
.rt-tr.-even.disabled {
    background: #00000000!important;
}

.detailview-content{
    min-height: 80vh;
}