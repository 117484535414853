.NextButton[disabled] {
    height: 2.5em;
    border-radius: 15px;
    border-color: transparent;
    color: white;
    font-size: 20px;
    background-color: #bab8bb;
    min-width: 9%;
    margin-top: 1%;
    cursor: no-drop;
  }
  
  .NextButton {
    height: 2.5em;
    border-radius: 15px;
    border-color: transparent;
    color: white;
    font-size: 20px;
    background-color: #205a8c;
    width: 10em;
    cursor: pointer;
  }
  
  .back {
    background-color: #e9e9e9;
    color: #777777;
    margin-right: 10%;
    width: 2.5em;
  }

  .leave {
  background-color: #b9b9b9;
  margin-left: 150px;
  margin-top: 30px;
}


.yesButton {
  margin-top: 30px;
}