.SubscriptionContainer {
    display: inline-flex;
    margin: 30px 0px 30px 0px;
    max-width: 70%;
    overflow-x: scroll;
}

.Subscription {
    padding: 15px;
    margin-right: 30px;
    min-width: 205px;
    text-align: left;
    border-radius: 20px;
    background-color: #FFFFFF;
}

.Subscription:last-child {
    margin-right: 0px;
}

.SubscriptionHeader {
    font-size: 18px;
    color: #B8B9BB;
}

.SubscriptionTitle {
    display: block;
    font-size: 22px;
    font-weight: bold;
    color: #000000;
}

.SubscriptionDetails {
    display: inline-grid;
    margin: 15px 0px 15px 0px;
    font-size: 18px;
    color: #777777;
}

.SubscriptionSelect {
    display: block;
    width: 100%;
    height: 3em;
    border-radius: 15px;
    border-width: 0px;
    font-size: 18px;
    font-weight: bold;
    color: #3B8DBF;
    background-color: #F5F9FD;
    cursor: pointer;
}

.selected {
    background-color: #3B8DBF;
    color: #FFFFFF;
}