body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.body, #root {
   width: 100%;
   height: 100vh;
}

:focus {
    outline: none;
}

/* @media only screen and (min-width: 1500px) { */
    .ui.container {
        width: 98%;
        margin-left: auto !important;
        margin-right: auto !important
    }
/* } */


@media only screen and (min-width: 768px) {
    .form-label {
        text-align: right;
        width: 100%!important;
        margin-right: 15px!important;
    }
}

@media only screen and (max-width: 767px) {
    .form-control {
        margin-left: 15px!important;
    }
}


.ui.progress .bar>.progress {
    right: .1em;
}

.form-control {
    font-weight: 100!important;
}

.rt-th {
    text-align: left !important;
}

.-headerGroups {
    display: none !important;
}

.rt-thead {
    border-bottom-style: double;
    border-color: #0000001a;
}

.rt-tr {
    border-bottom: 1px solid #0000001a;
}

.ReactTable.-highlight .rt-tbody .rt-tr:not(.-padRow):hover {
    background: #def;
}

.ReactTable .rt-thead.-header {
    box-shadow: none!important;
}

.rt-td .ui.button {
    padding-top: 0.55em;
    padding-bottom: 0.45em;
}

.Table__itemCount {
    /* margin-top: 10px; */
    font-size: 14px;
}

.Table__pagination {
    display: flex;
    justify-content: flex-end;
    padding: 20px 10px 10px;
}

.Table__pageButton {
    font-size: 15px;
    outline: none;
    border: none;
    background-color: transparent;
    cursor: pointer;
}

.Table__pageButton:disabled {
    cursor: not-allowed;
    color: gray;
}

.Table__pageButton--active {
    color: #1b1c1d;
    font-weight: bold;
}


.ReactTable .rt-noData {
    top: 55%!important;
    background: #fff0;
}


.rt-tr.-odd.disabled {
    background: #00000008!important;
}
.rt-tr.-even.disabled {
    background: #00000000!important;
}

.detailview-content{
    min-height: 80vh;
}
.Wizard {
    background-color: #f5f9fd;
    text-align: center;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    height: 100vh;
}

.leaveButton {
    position: absolute;
    top: 20px;
    left: 20px;
    background-color: transparent;
    border-width: 0px;
    cursor: pointer;
}

.leaveButton > i {
    font-size: 28px;
    opacity: 0.3;
    transition: 200ms;
}

.leaveButton > i:hover {
    opacity: 0.8;
}

.step {
    width: 100%;
}

.addButton{
    margin-right: 5%;
}
#departements{
    margin-left: 5%;
}

.nextButtonTransferList{
    margin-top: 2%;
}
.Question {
    color: #205A8C;
    font-size: 36px;
    font-weight: 500;
    margin-bottom: 5px;
}
.Tip {
    color: #828282;
    font-size: 24px;
    font-weight: lighter;
    padding-top: 0;
}
.TextBox {
  border-style: solid;
  border-radius: 15px;
  border-width: 1px;
  border-color: #b8b9bb;
  text-align: center;
  font-size: 24px;
  color: #b8b9bb;
  height: 2.5em;
  width: 40%;
  background-color: transparent;
  margin: 30px 0px 30px 0px;
}

.TextBox:focus {
  outline: none;
  border-color: #3b8dbf;
  color: #777777;
  background-color: #ffffff;
}
.SubscriptionContainer {
    display: inline-flex;
    margin: 30px 0px 30px 0px;
    max-width: 70%;
    overflow-x: scroll;
}

.Subscription {
    padding: 15px;
    margin-right: 30px;
    min-width: 205px;
    text-align: left;
    border-radius: 20px;
    background-color: #FFFFFF;
}

.Subscription:last-child {
    margin-right: 0px;
}

.SubscriptionHeader {
    font-size: 18px;
    color: #B8B9BB;
}

.SubscriptionTitle {
    display: block;
    font-size: 22px;
    font-weight: bold;
    color: #000000;
}

.SubscriptionDetails {
    display: inline-grid;
    margin: 15px 0px 15px 0px;
    font-size: 18px;
    color: #777777;
}

.SubscriptionSelect {
    display: block;
    width: 100%;
    height: 3em;
    border-radius: 15px;
    border-width: 0px;
    font-size: 18px;
    font-weight: bold;
    color: #3B8DBF;
    background-color: #F5F9FD;
    cursor: pointer;
}

.selected {
    background-color: #3B8DBF;
    color: #FFFFFF;
}
.NextButton[disabled] {
    height: 2.5em;
    border-radius: 15px;
    border-color: transparent;
    color: white;
    font-size: 20px;
    background-color: #bab8bb;
    min-width: 9%;
    margin-top: 1%;
    cursor: no-drop;
  }
  
  .NextButton {
    height: 2.5em;
    border-radius: 15px;
    border-color: transparent;
    color: white;
    font-size: 20px;
    background-color: #205a8c;
    width: 10em;
    cursor: pointer;
  }
  
  .back {
    background-color: #e9e9e9;
    color: #777777;
    margin-right: 10%;
    width: 2.5em;
  }

  .leave {
  background-color: #b9b9b9;
  margin-left: 150px;
  margin-top: 30px;
}


.yesButton {
  margin-top: 30px;
}
.Summary{
    padding: 40px;
    margin: auto;
    width: 45%;
    text-align: left;
    border-radius: 20px;
    background-color: #FFFFFF;
}

.FullWidth {
    width: 100%;
}

.SummaryTitle{
    font-size: 24px;
    font-weight: bold;
}

.SummaryDetails {
    display: inline-flex;
    justify-content: space-between;
    width: 100%;
}

.SummaryDetails span {
    margin-top: 15px;
}

.SummaryLabels {
    display: grid;
    font-size: 18px;
    color: #777777;
}

.SummaryValues {
    display: grid;
    display: grid;
    font-size: 18px;
    text-align: right;
}

.editButton {
    margin-left: 10px; 
    border-width: 0;
    background-color: transparent;
    cursor: pointer;
}
.Select {
  border-style: solid;
  border-radius: 15px;
  border-width: 1px;
  text-align: center;
  font-size: 22px;
  height: 2.5em;
  min-width: 40%;
  border-color: #3b8dbf;
  color: #777777;
  background-color: #ffffff;
  margin: 30px 0px 30px 0px;
}

.select-selected:after {
  position: absolute;
  right: 15px;
}

select option {
  font-size: 20px;
}

.TextBox{
    margin: 30px 30px 30px 50px;
}

.back{
    margin-top: 2%;
}

.SummaryContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
    gap: 10px;
    margin: auto;
    width: 90%;
    height: 500px;
    overflow-y: scroll;
}

.tableContainer {
    background-color: #fff;
    width: 100%;
    margin: auto;
    max-height: 450px;
    overflow-y: scroll;
}

.extensionTable{
    margin: auto;
    width: 30%;
    border-collapse: collapse;
}

.extensionInput {
    border-width: 0px;
    font-size: 15px;
    text-align: center;
    border-radius: 4px;
    padding: 10px 10px;
}

.extensionInput.error {
    border: solid;
    border-width: 2px;
    border-color: #205a8c;
}

.tableHeader {
    font-size: 15px;
    font-weight: bold;
}

.tableHeader tr td {
    padding: 10px 10px;
}

.tableRow:hover {
    background-color: #E8F3FF;
}

.deleteButton {
    border-width: 0;
    background-color: transparent;
    cursor: pointer;
    color: #000;
}

.deleteButton:hover {
    color: #ce4848;
}

.addButton{
    background-color: transparent;
    border-width: 0px;
    margin: auto;
    margin-top: 15px;
    font-size: 28px;
    color: #205a8c;
    cursor: pointer;
}

.selectedUads{
    font-size: 15px;
    border-radius: 4px;
    padding: 10px 10px;
}

.selectedUads.error {
    border: solid;
    border-width: 2px;
    border-color: #205a8c;
}
/* Conteneur principal */
.react-dual-listbox {
  background-color: transparent;
  width: 60%;
  height: 200px;
  margin: auto;
}

.react-dual-listbox * {
  border-radius: 10px;
}

/* Conteneurs listes */
.rdl-list-box {
  background-color: white;
}

/* Champs de recherche */
.rdl-filter{
  border: none;
  background-color: #f1f1f1;
  text-align: center;
}

.rdl-filter:focus{
  border-color: #3b8dbf !important; 
  color: #777777 !important;
}

/* Champs select */
.rdl-control {
  border: none;
}

/* Boutons tout déplacer */
.rdl-move-all{
  display: none;
}

/* Bouton déplacer à droite */
.rdl-move-right {
  color: #ffffff;
  background-color: #205a8c;
  border-width: 0px;
  outline: none;
}

.rdl-move-right:hover {
  color: #ffffff !important;
  background-color: #205a8c !important;
}

.rdl-move-right:focus {
  color: #ffffff !important;
  background-color: #205a8c !important;
}

/* Icône bouton déplacer à droite */
.rdl-move-right i {
  font-size: 22px;
}

/* Bouton déplacer à gauche */
.rdl-move-left {
  color: #777777;
  background-color: #E9E9E9;
  border-width: 0px;
  outline: none;
}

.rdl-move-left:hover {
  color: #777777 !important;
  background-color: #E9E9E9 !important;
}

/* Icône bouton déplacer à gauche */
.rdl-move-left i {
  font-size: 22px;
}







