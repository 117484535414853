.TextBox {
  border-style: solid;
  border-radius: 15px;
  border-width: 1px;
  border-color: #b8b9bb;
  text-align: center;
  font-size: 24px;
  color: #b8b9bb;
  height: 2.5em;
  width: 40%;
  background-color: transparent;
  margin: 30px 0px 30px 0px;
}

.TextBox:focus {
  outline: none;
  border-color: #3b8dbf;
  color: #777777;
  background-color: #ffffff;
}