.Select {
  border-style: solid;
  border-radius: 15px;
  border-width: 1px;
  text-align: center;
  font-size: 22px;
  height: 2.5em;
  min-width: 40%;
  border-color: #3b8dbf;
  color: #777777;
  background-color: #ffffff;
  margin: 30px 0px 30px 0px;
}

.select-selected:after {
  position: absolute;
  right: 15px;
}

select option {
  font-size: 20px;
}
