.Wizard {
    background-color: #f5f9fd;
    text-align: center;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    height: 100vh;
}

.leaveButton {
    position: absolute;
    top: 20px;
    left: 20px;
    background-color: transparent;
    border-width: 0px;
    cursor: pointer;
}

.leaveButton > i {
    font-size: 28px;
    opacity: 0.3;
    transition: 200ms;
}

.leaveButton > i:hover {
    opacity: 0.8;
}

.step {
    width: 100%;
}

.addButton{
    margin-right: 5%;
}
#departements{
    margin-left: 5%;
}

.nextButtonTransferList{
    margin-top: 2%;
}