.TextBox{
    margin: 30px 30px 30px 50px;
}

.back{
    margin-top: 2%;
}

.SummaryContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    margin: auto;
    width: 90%;
    height: 500px;
    overflow-y: scroll;
}

.tableContainer {
    background-color: #fff;
    width: 100%;
    margin: auto;
    max-height: 450px;
    overflow-y: scroll;
}

.extensionTable{
    margin: auto;
    width: 30%;
    border-collapse: collapse;
}

.extensionInput {
    border-width: 0px;
    font-size: 15px;
    text-align: center;
    border-radius: 4px;
    padding: 10px 10px;
}

.extensionInput.error {
    border: solid;
    border-width: 2px;
    border-color: #205a8c;
}

.tableHeader {
    font-size: 15px;
    font-weight: bold;
}

.tableHeader tr td {
    padding: 10px 10px;
}

.tableRow:hover {
    background-color: #E8F3FF;
}

.deleteButton {
    border-width: 0;
    background-color: transparent;
    cursor: pointer;
    color: #000;
}

.deleteButton:hover {
    color: #ce4848;
}

.addButton{
    background-color: transparent;
    border-width: 0px;
    margin: auto;
    margin-top: 15px;
    font-size: 28px;
    color: #205a8c;
    cursor: pointer;
}

.selectedUads{
    font-size: 15px;
    border-radius: 4px;
    padding: 10px 10px;
}

.selectedUads.error {
    border: solid;
    border-width: 2px;
    border-color: #205a8c;
}