/* Conteneur principal */
.react-dual-listbox {
  background-color: transparent;
  width: 60%;
  height: 200px;
  margin: auto;
}

.react-dual-listbox * {
  border-radius: 10px;
}

/* Conteneurs listes */
.rdl-list-box {
  background-color: white;
}

/* Champs de recherche */
.rdl-filter{
  border: none;
  background-color: #f1f1f1;
  text-align: center;
}

.rdl-filter:focus{
  border-color: #3b8dbf !important; 
  color: #777777 !important;
}

/* Champs select */
.rdl-control {
  border: none;
}

/* Boutons tout déplacer */
.rdl-move-all{
  display: none;
}

/* Bouton déplacer à droite */
.rdl-move-right {
  color: #ffffff;
  background-color: #205a8c;
  border-width: 0px;
  outline: none;
}

.rdl-move-right:hover {
  color: #ffffff !important;
  background-color: #205a8c !important;
}

.rdl-move-right:focus {
  color: #ffffff !important;
  background-color: #205a8c !important;
}

/* Icône bouton déplacer à droite */
.rdl-move-right i {
  font-size: 22px;
}

/* Bouton déplacer à gauche */
.rdl-move-left {
  color: #777777;
  background-color: #E9E9E9;
  border-width: 0px;
  outline: none;
}

.rdl-move-left:hover {
  color: #777777 !important;
  background-color: #E9E9E9 !important;
}

/* Icône bouton déplacer à gauche */
.rdl-move-left i {
  font-size: 22px;
}






